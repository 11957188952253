<template>
  <div v-if="!loadingData">
    <v-tour :options="tourOptions" name="addMission" :steps="steps"></v-tour>
    <div class="title-page flex justify-between text-center">
      <div class="flex">
        <feather-icon icon="PackageIcon" svgClasses="h-4 w-4"></feather-icon>
        <h3 class="font-bold ml-2" style="font-size: 1.125rem">
          {{ $t('addMission') }}
        </h3>
      </div>
    </div>
    <div class="line"></div>
    <!-- Add Mission -->
    <div class="p-6 bg-white all-inputs">
      <vs-alert class="mb-4" :active="error_msg" color="danger" icon="new_releases">
        <span>{{ err_msg }}</span>
      </vs-alert>
      <!-- Type -->
      <div class="allow-days">
        <svg width="14" height="14" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11 15V11M11 7H11.01M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
            stroke="#212121"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <h3>يرجي العلم ان الايام المتاحة للتوريد هي :</h3>
        <span v-for="(supply_day, index) in supply_days" :key="index">{{ supply_day }}</span>
      </div>
      <div class="w-full inner-all-inputs">
        <div data-mission-tour-step="1" class="my-select">
          <label class="font-bold"
            >{{ $t('type') }}
            <span style="font-size: 1.2rem" class="text-danger font-bold">*</span>
            :</label
          >
          <SelectInputVue
            v-validate="'required'"
            :options="typeOfMissions"
            :value="missionData.type"
            @input="(val) => missionData.type = val"
            name="missionType"
          />
          <span style="font-size: 0.7rem" class="text-danger font-bold">{{ errors.first('missionType') }}</span>
        </div>

        <!-- Payment Way -->
        <div v-if="missionData.type && missionData.type != 1" class="my-select">
          <label class="font-bold"
            >{{ $t('paymentWay') }}
            <span style="font-size: 1.2rem" class="text-danger font-bold">*</span>
            :</label
          >
          <SelectInputVue
            v-validate="'required'"
            :options="paymentWayItems"
            :value="missionData.payment_way"
            @input="(val) => missionData.payment_way = val"
            name="paymentWay"
          />
          <span style="font-size: 0.7rem" class="text-danger font-bold">{{ errors.first('paymentWay') }}</span>
        </div>

        <!-- Payment Bank -->
        <!-- CONDITION: 'بنك' && not 'استلام' | 'مرتجعات/استلام مرتجعات' -->
        <div v-if="missionData.payment_way === 3 && ![1, 4].includes(missionData.type)" class="my-select">
          <label class="font-bold"
            >{{ $t('accountNumber') }}
            <span style="font-size: 1.2rem" class="text-danger font-bold">*</span>
            :</label
          >
          <SelectInputVue
            v-validate="'required'"
            :options="bankAccounts"
            :value="missionData.bank_account_id"
            @input="(val) => missionData.bank_account_id = val"
            name="bankAccount"
          />
          <span style="font-size: 0.7rem" class="text-danger font-bold">{{ errors.first('bankAccount') }}</span>
        </div>

        <!-- Address -->
        <div class="my-select">
          <label class="font-bold"
            >{{ $t('address') }}
            <span style="font-size: 1.2rem" class="text-danger font-bold">*</span>
            :</label
          >
          <SelectInputVue
            v-validate="'required'"
            :options="addressMissions"
            :value="missionData.address_id"
            @input="(val) => missionData.address_id = val"
            name="address"
          />
          <span style="font-size: 0.7rem" class="text-danger font-bold">{{ errors.first('address') }}</span>
        </div>

        <!-- Due Date -->
        <div style="position: relative">
          <label class="vs-input--label font-bold"
            >{{ $t('dueDate') }}
            <span v-if="!missionData.month" style="font-size: 1.2rem" class="text-danger font-bold">*</span>
            :</label
          >
          <datepicker
            v-validate="!missionData.month ? 'required' : ''"
            :language="ar"
            placeholder=""
            v-model="missionData.due_date"
            format="dd-MM-yyyy"
            name="dueDate"
            :disabled="missionData.month ? true : false"
            :disabledDates="disabledDates"
          ></datepicker>
          <svg
            @click="missionData.due_date = null"
            v-if="missionData.due_date"
            class="remove-icon"
            width="10"
            height="10"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 1L1 11M1 1L11 11"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span style="font-size: 0.7rem" class="text-danger font-bold">{{ errors.first('dueDate') }}</span>
        </div>

        <!-- bulk -->
        <div class="my-select" v-if="this.$acl.not.check('isPublicCompany')">
          <label class="font-bold">{{ $t('duringDuration') }} :</label>
          <SelectInputVue
            :options="availabelMonth"
            :disabled="missionData.due_date ? true : false"
            :value="missionData.month"
            @input="(val) => missionData.month = val"
          />
        </div>

        <!-- Notes -->
        <div class="my-select">
          <label class="font-bold">{{ $t('notes') }} </label> :
          <vs-input name="notesMission" maxlength="250" v-model="missionData.notes" class="w-full" />
        </div>
      </div>
    </div>
    <div class="flex flex-wrap items-center py-6 justify-end" slot="footer">
      <button class="save-order" :class="{ 'opacity-btn': !isFormValid }" @click="submitData" :disabled="!isFormValid">
        {{ loading ? 'جاري الحفظ' : $t('saveMission') }}
      </button>
      <button
        ref="notification"
        color="success"
        type="flat"
        style="display: none"
        @click="
          $vs.notify({
            title: $t('success'),
            text: $t('addToast'),
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
          })
        "
      ></button>

      <button
        ref="faildNotification"
        color="success"
        type="flat"
        style="display: none"
        @click="
          $vs.notify({
            title: $t('faild'),
            text: $t('faildAdd'),
            color: 'danger',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
          })
        "
      ></button>
    </div>
  </div>
  <div class="flex justify-center items-center" style="height: 70vh" v-else>
    <SpinnerColor2Vue />
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { ar } from 'vuejs-datepicker/dist/locale';
import { mapActions, mapGetters, mapState } from 'vuex';
import tour from '@/mixins/tour.js';
import SpinnerColor2Vue from '../components/table-components/SpinnerColor2.vue';
import SelectInputVue from '../../layouts/components/SelectInput.vue';

export default {
  mixins: [tour],
  components: {
    Datepicker,
    SelectInputVue,
    SpinnerColor2Vue,
  },
  data() {
    return {
      loading: false,
      ar,
      dueDate: null,
      disabledDates: {
        to: new Date(Date.now()),
      },
      missionData: {
        type: null,
        address_id: null,
        due_date: null,
        notes: '',
        payment_way: null,
        bank_account_id: null,
        month: null,
      },
      typeOfMissions: [
        {
          id: 1,
          name: 'إستلام',
        },
        {
          id: 2,
          name: 'توريد',
        },
        {
          id: 3,
          name: 'إستلام وتوريد',
        },
        {
          id: 4,
          name: 'مرتجعات / استلام ومرتجعات',
        },
      ],
      bankAccounts: [],
      addressMissions: [],
      error_msg: false,
      loadingData: false,
      steps: [
        {
          target: '[data-mission-tour-step="1"]', // We're using document.querySelector() under the hood
          header: {
            title: 'اختر نوع المهمة',
          },
          content: `حدد طلبك وقم بالاختيار`,
          params: {
            placement: 'top',
            enableScrolling: false,
          },
        },
        {
          target: '[data-tour-step="6"]',
          header: {
            title: 'التقارير',
          },
          content: ` من هنا يمكنك تحميل تقرير الطرود وتقرير المهمات`,
        },
      ],
      availabelMonth: [
        { id: 1, name: 'شهر' },
        { id: 2, name: 'شهرين' },
        { id: 3, name: '3 شهور' },
      ],
    };
  },
  computed: {
    ...mapGetters('dataList', [
      'address_of_mission',
      'bank_accounts',
      'payment_way',
      'missions_withiin_two_days',
      'suppleid_available',
      'err_msg',
    ]),
    ...mapState('dataList', ['supply_days']),
    isFormValid() {
      return !this.errors.any();
    },

    paymentWayItems() {
      if (this.missionData.type === 4) {
        // hide bank option
        return this.payment_way.filter(item => item.id !== 3);
      }
      return this.payment_way;
    },
  },
  methods: {
    ...mapActions('dataList', ['fetchAddressMissions', 'postMission']),
    initValues() {
      this.missionData.type = 1;
      this.missionData.address_id = '';
      this.missionData.notes = '';
      this.missionData.payment_way = null;
      this.missionData.bank_account_id = 0;
      this.missionData.due_date = '';
    },
    submitData() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          if (this.missionData.due_date) {
            this.missionData.due_date = new Date(this.missionData.due_date).toISOString().split('T')[0];
          }
          this.loading = true;
          await this.postMission(this.missionData);
          this.loading = false;
          if (this.$store.state.dataList.successApi == 1) {
            this.$refs.notification.click();
            this.$router.push({ path: `Missions` });
            this.error_msg = false;
            this.initValues();
          } else {
            this.$refs.faildNotification.click();
            this.error_msg = true;
          }
        }
      });
    },
    disableWeekends(date) {
      const day = new Date(date).getDay();
      return day === 1 || day === 5;
    },
  },
  async created() {
    this.loadingData = true;
    await this.fetchAddressMissions();
    this.addressMissions = this.address_of_mission;
    this.bankAccounts = this.bank_accounts;
    this.loadingData = false;
  },
  mounted() {
    if (this.runWalkthrough()) {
      // this.startTour("addMission");
      setTimeout(() => {
        window.scrollTo(0, 20);
      }, 500);
      this.$watch(
        () => {
          return this.$tours.addMission.currentStep;
        },
        val => {
          this.$store.dispatch('updateStepTour', val);
          if (val == 1) {
            const nextStep = document.querySelector('.v-step__button');
            nextStep.addEventListener('click', () => {
              this.$router.push({ path: '/reports/AllOrders' });
            });
          }
        }
      );
    }
    if (this.$acl.check('isPublicCompany')) {
      this.disabledDates = {
        to: new Date(Date.now()),
        days: [5],
      };
    }
  },

  watch: {
    'missionData.type': {
      handler(newVal) {
        if (newVal === 4) {
          // مرتجعات / استلام ومرتجعات
          if (this.missionData.payment_way === 3) {
            // 'bank'
            this.missionData.payment_way = null;
          }
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vuexy/_variables.scss';

.title-page {
  margin-bottom: 1.3rem;
  margin-top: 1.3rem;
}
.line {
  border-bottom: 0.03125rem solid #70707061;
  opacity: 50%;
  margin-bottom: 1.3rem;
}
.save-order {
  font-family: 'Cairo', sans-serif;
  background-color: $mainColor1;
  color: #fff;
  width: 6rem;
  height: 2.375rem;
  border-radius: 0.25rem;
  border: none;
  font-size: 0.875rem;
  cursor: pointer;
}
.custom-select {
  height: 38px;
  .selected {
    font-size: 0.75rem;
    color: #747474;
    font-weight: bold;
  }
}
.opacity-btn {
  opacity: 0.5;
  cursor: default;
}
.my-select {
  position: relative;
  border-radius: 4px;
  label {
    font-size: 0.85rem;
    color: rgba(0, 0, 0, 0.6);
    padding-left: 5px;
  }
  input {
    padding: 0px 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    font-size: 0.75rem;
    color: #747474;
    cursor: pointer;
  }
  input:focus {
    border: 1px solid $mainColor1;
  }
  .remove-icon {
    top: 36px;
    background: #fff;
  }
  i {
    position: absolute;
    top: 32px;
    right: 15px;
  }
  .custom-select {
    height: 38px;
  }
}
.allow-days {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  gap: 8px;
  h3 {
    font-size: 16px;
    font-weight: 600;
    color: #212121;
  }
  span {
    background-color: $mainColor2;
    color: $mainColor1;
    font-weight: bold;
    padding: 2px 12px;
    border-radius: 4px;
  }
}
.remove-icon {
  position: absolute;
  top: 40px;
  right: 12px;
  z-index: 99999;
}
</style>
<style lang="scss">
@import '@/assets/scss/vuexy/_variables.scss';
.all-inputs {
  .inner-all-inputs {
    gap: 22px;
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
  }
  .custom-select {
    .selected {
      font-size: 0.75rem !important;
      color: #747474 !important;
    }
  }
  .input-select-con,
  .vs-con-input {
    input,
    .all-inputs .vs-con-input-label .vs-con-input input {
      font-size: 0.75rem;
      font-family: 'Cairo';
      height: 38px !important;
    }
  }
  .vs-con-input-label {
    padding-right: unset !important;
  }
}
.vs-select--options {
  ul {
    .vs-component {
      .vs-select--item {
        span {
          font-family: 'Cairo';
          font-size: 0.75rem;
        }
      }
      .activex {
        span {
          color: $mainColor1;
        }
      }
    }
  }
}
.vdp-datepicker {
  div {
    input:focus {
      border-color: $mainColor1 !important;
    }
    input {
      height: 38px !important;
      padding: 0px 10px;
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      font-size: 0.75rem;
      color: #747474;
      cursor: pointer;
    }
  }
  .vdp-datepicker__calendar {
    .cell {
      border-radius: 50%;
      height: 14%;
      &:hover {
        border-color: $mainColor1 !important;
      }
    }
    .selected {
      background-color: $mainColor1;
      color: #fff;
      &:hover {
        background-color: $mainColor1;
      }
    }
  }
}
@media (min-width: 300px) and (max-width: 767px) {
  .all-inputs {
    .inner-all-inputs {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
</style>
